import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { REMOTESERVERS } from '../../../../constants/applicationConstants';
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
const BaseTestDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
const TestsDataTable = DataTableHoc(BaseTestDataTable);

/**
 * RemoteServers main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RemoteServers component.
 */
const RemoteServers = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const navigate = useNavigate();
  /**
   * navigate to edit screen
   * @param {*} event
   */
  const editScreen = (dataItem) => {
    navigate(`/remote-server`, {
      state: {
        edit: true,
        dataSet: dataItem,
      },
    });
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <TestsDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={REMOTESERVERS}
            dataColumns={dataColumnsArray}
            gridColumnsList={() => GridColumnsList({ editScreen })}
            dataTableName={REMOTESERVERS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${REMOTESERVERS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${REMOTESERVERS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default RemoteServers;
