import React from 'react';
import { convertToLocalDate } from 'smart-react';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({ editScreen }) => {
  const dataColumns = [
    {
      field: 'uc_env_id',
      title: 'Remote Server',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className='edit-link'
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.uc_env_id}
        </td>
      ),
    },
    {
      field: 'TenantName',
      title: 'Tenant',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 150,
    },
    {
      field: 'uc_host',
      title: 'Host',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_mtf_host',
      title: 'MTF Host',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },

    {
      field: 'uc_mtf_port',
      title: 'MTF Port',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_mtf_ssh_id',
      title: 'MTF SSH Username',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_mtf_ssh_pswd',
      title: 'MTF SSH Password',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_url',
      title: 'Web URL',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_web_start_title',
      title: 'Web Start Title',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_web_start_lbl',
      title: 'Web Start Label',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'wh_id',
      title: 'Warehouse ID',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'usr_id',
      title: 'UserDetail ID',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'usr_pswd',
      title: 'Password',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'devcod',
      title: 'Devcod',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_client_id',
      title: 'Client ID',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_cluster_nodes',
      title: 'Cluster Nodes',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_device_grp',
      title: 'Device Group',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_devcod_tmpl',
      title: 'Device Code',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_usr_id_tmpl',
      title: 'UserDetail Id',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_rf_ven_nam',
      title: 'RF Vendor Name',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'ins_user_id',
      title: 'Created By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'ins_dt',
      title: 'Created Date',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.ins_dt)}</td>,
    },
    {
      field: 'last_upd_user_id',
      title: 'Modified By',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'last_upd_dt',
      title: 'Modified Date',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.last_upd_dt)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
