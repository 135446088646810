import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import { AUTEST_REMOTE_SERVER } from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
import {doubleEncode} from "../../../../Utils/Encode/UrlHelper";
/**
 * list Remote Servers.
 * @returns {Promise} - A promise that resolves with the list of Remote Servers or rejects with an error.
 * @param props
 */
export const listRemoteServer = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset === 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${AUTEST_REMOTE_SERVER}/Filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker,
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    remoteServer: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Remote Server.
 * @returns {Promise} - A promise that resolves with the creation of Remote Server or rejects with an error.
 * @param data
 */
export const createRemoteServer = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_REMOTE_SERVER}`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Remote Server.
 * @returns {Promise} - A promise that resolves with the update of Remote Server or rejects with an error.
 * @param data
 */
export const updateRemoteServer = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_REMOTE_SERVER}/${data?.tenant_id}`,
    method: 'PUT',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * delete Remote Server.
 * @returns {Promise} - A promise that resolves with the update of Remote Server or rejects with an error.
 * @param data
 */
export const deleteRemoteServer = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_REMOTE_SERVER}/${doubleEncode(data?.tenant_id)}?envId=${encodeURIComponent(data?.uc_env_id)}&tenantid=${encodeURIComponent(data?.tenant_id)}`,
    method: 'DELETE',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Remote Server Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of Remote Server or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
