import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback, TextInput, DropDownInput } from 'smart-react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Error } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';
import {
  buildNotification,
  GenerateNotification,
  isValidate,
  isFromValid,
} from 'smart-react';
import {
  createRemoteServer,
  updateRemoteServer,
  listRemoteServer,
} from '../Services/RemoteServerService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Field } from '@progress/kendo-react-form';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import { USER_TENANTS } from '../../../../constants/applicationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../Core/Context/AuthContext';
import { SplitButton, SplitButtonItem } from '@progress/kendo-react-buttons';
import {
  findTenant,
  ValidatePermissions,
} from '../../../../Utils/Auth/ValidatePermissions';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
} from '../../../../constants/notificationMessages';
/**
 * Add Remote Server main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add Remote Server component.
 */
const AddRemoteServer = () => {
  const navigate = useNavigate();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  const tenantList = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) => userTenant.IsWrite === true,
  );
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [tenant, setTenant] = React.useState(
    JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
      (userTenant) => userTenant.TenantId === tenantID,
    )[0],
  );
  const [loading, setLoading] = React.useState(false);
  const [validateForm, setValidateForm] = React.useState(false);
  const [formData, setFormData] = React.useState({
    tenant_id: tenantID,
    TenantName: tenant?.TenantName,
    uc_env_id: '',
    uc_host: '',
    uc_mtf_host: '',
    uc_mtf_port: '',
    uc_mtf_ssh_id: '',
    uc_mtf_ssh_pswd: '',
    uc_url: '',
    uc_web_start_title: '',
    uc_web_start_lbl: '',
    wh_id: '',
    usr_id: '',
    usr_pswd: '',
    devcod: '',
    uc_ossi_client_id: '',
    uc_ossi_cluster_nodes: '',
    uc_ossi_device_grp: '',
    uc_ossi_devcod_tmpl: '',
    uc_ossi_usr_id_tmpl: '',
    uc_ossi_rf_ven_nam: '',
  });
  const requiredFields = [
    'uc_env_id',
    'uc_host',
    'uc_mtf_host',
    'uc_mtf_port',
    'uc_mtf_ssh_id',
    'uc_mtf_ssh_pswd',
    'wh_id',
  ];
  const { state } = useLocation();

  React.useEffect(() => {
    if (state) {
      const { accessToken } = getTokensFromStorage();

      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await listRemoteServer({
          accessToken: accessToken,
          limit: '50',
          offset: 1,
          filter: [
            {
              ColumnName: 'uc_env_id',
              Op: 'eq',
              ColumnValue: dataSet?.uc_env_id,
            },
          ],
          moduleName: 'RemoteServer',
          isServiceWorker: false,
        });
        const testData = response.remoteServer[0];
        setFormData(testData);
        setLoading(false);
      })();
      setFormData({ ...dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
        (userTenant) => userTenant.TenantId === tenantID,
      )[0];
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        tenant_id: tenantID,
        TenantName: tempTenant?.TenantName,
      }));
    }
  }, [tenantID]);

  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    let tempTenant = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
      (userTenant) => userTenant.TenantId === e.value.TenantId,
    )[0];
    setTenant(tempTenant);
    setFormData((prevState) => ({
      ...prevState,
      tenant_id: e.value.TenantId,
      TenantName: tempTenant?.TenantName,
    }));
  };

  /**
   * onSave
   * submit the remote server data to api
   */
  const onSave = async ({ isExit = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
    };
    let response;
    let method;
    if (state?.edit) {
      method = updateRemoteServer;
      notificationMetaData = UPDATE_MESSAGE;
    } else {
      method = createRemoteServer;
      notificationMetaData = CREATE_MESSAGE;
      setFormData({
        tenant_id: tenantID,
        TenantName: tenant?.TenantName,
        uc_env_id: '',
        uc_host: '',
        uc_mtf_host: '',
        uc_mtf_port: '',
        uc_mtf_ssh_id: '',
        uc_mtf_ssh_pswd: '',
        uc_url: '',
        uc_web_start_title: '',
        uc_web_start_lbl: '',
        wh_id: '',
        usr_id: '',
        usr_pswd: '',
        devcod: '',
        uc_ossi_client_id: '',
        uc_ossi_cluster_nodes: '',
        uc_ossi_device_grp: '',
        uc_ossi_devcod_tmpl: '',
        uc_ossi_usr_id_tmpl: '',
        uc_ossi_rf_ven_nam: '',
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: 'remoteserver',
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        if (isExit) {
          navigate('/remote-servers');
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to remote-servers listing screen
   */
  const onExit = () => {
    navigate('/remote-servers');
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <>
            <div className='add-test main-page-section add-page-section'>
              <Card className='tophead'>
                <CardHeader>
                  <div className='action-buttons-container'>
                    <Link
                      className='action-icon-wrapper'
                      to={`/remote-servers`}
                    >
                      <FontAwesomeIcon
                        className='icon-arrow k-mr-2'
                        icon={faArrowLeft}
                      />
                    </Link>
                    <p className='k-align-items-center k-d-flex k-mb-0 display-text'>
                      Remote Server: {formData?.uc_env_id}
                    </p>
                  </div>
                </CardHeader>
              </Card>
              <Card className='k-mt-3 detail-card'>
                <CardHeader>
                  <h6 className='card-title-secondary'>Details</h6>
                </CardHeader>
                <CardBody className='detail-card'>
                  <div>
                    <Form
                      onSubmit={onSave}
                      render={(formRenderProps) => (
                        <div className='card-content'>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div className='field-wrapper'>
                              <DropDownInput
                                textField='TenantName'
                                label='Tenant'
                                dataItemKey='TenantId'
                                className='tenant-dropdown k-align-self-end'
                                dataSet={tenantList}
                                value={tenant}
                                defaultItem={{
                                  TenantName: 'Select Tenant ...',
                                  disabled: true,
                                }}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                handleChange={handleDropdownChange}
                                filterable={true}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div
                              className={`field-wrapper  ${isValidate(validateForm, formData?.uc_env_id) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_env_id'}
                                id={'uc_env_id'}
                                name={'uc_env_id'}
                                data={formData?.uc_env_id}
                                onChange={handleInputChange}
                                label={'Remote Server *'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={state?.edit ? 'ReadOnly' : ''}
                                optional={false}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_env_id,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.uc_host) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_host'}
                                id={'uc_host'}
                                name={'uc_host'}
                                data={formData?.uc_host}
                                onChange={handleInputChange}
                                label={'Host *'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                              />
                              {isValidate(validateForm, formData?.uc_host) && (
                                <Error>This field cannot be empty</Error>
                              )}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-80'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.uc_mtf_host) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_mtf_host'}
                                id={'uc_mtf_host'}
                                name={'uc_mtf_host'}
                                data={formData?.uc_mtf_host}
                                onChange={handleInputChange}
                                label={'MTF Host *'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_mtf_host,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.uc_mtf_port) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_mtf_port'}
                                id={'uc_mtf_port'}
                                name={'uc_mtf_port'}
                                data={formData?.uc_mtf_port}
                                onChange={handleInputChange}
                                label={'MTF Port *'}
                                type={'number'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_mtf_port,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.uc_mtf_ssh_id) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_mtf_ssh_id'}
                                id={'uc_mtf_ssh_id'}
                                name={'uc_mtf_ssh_id'}
                                data={formData?.uc_mtf_ssh_id}
                                onChange={handleInputChange}
                                label={'MTF SSH Username *'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_mtf_ssh_id,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div
                              className={`field-wrapper field-wrapper-75 ${isValidate(validateForm, formData?.uc_mtf_port) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'uc_mtf_ssh_pswd'}
                                id={'uc_mtf_ssh_pswd'}
                                name={'uc_mtf_ssh_pswd'}
                                data={formData?.uc_mtf_ssh_pswd}
                                onChange={handleInputChange}
                                label={'MTF SSH Password *'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                              {isValidate(
                                validateForm,
                                formData?.uc_mtf_ssh_pswd,
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-80'>
                            <div className={`field-wrapper field-wrapper-75`}>
                              <Field
                                key={'uc_url'}
                                id={'uc_url'}
                                name={'uc_url'}
                                data={formData?.uc_url}
                                onChange={handleInputChange}
                                label={'Web URL'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_web_start_title'}
                                id={'uc_web_start_title'}
                                name={'uc_web_start_title'}
                                data={formData?.uc_web_start_title}
                                onChange={handleInputChange}
                                label={'Web Start Title'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_web_start_lbl'}
                                id={'uc_web_start_lbl'}
                                name={'uc_web_start_lbl'}
                                data={formData?.uc_web_start_lbl}
                                onChange={handleInputChange}
                                label={'Web Start Label'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'usr_id'}
                                id={'usr_id'}
                                name={'usr_id'}
                                data={formData?.usr_id}
                                onChange={handleInputChange}
                                label={'UserDetail ID'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'usr_pswd'}
                                id={'usr_pswd'}
                                name={'usr_pswd'}
                                data={formData?.usr_pswd}
                                onChange={handleInputChange}
                                label={'Password'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-80'>
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.wh_id) ? 'field-invalid' : 'field-valid'}`}
                            >
                              <Field
                                key={'wh_id'}
                                id={'wh_id'}
                                name={'wh_id'}
                                data={formData?.wh_id}
                                onChange={handleInputChange}
                                label={'Warehouse ID *'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                              {isValidate(validateForm, formData?.wh_id) && (
                                <Error>This field cannot be empty</Error>
                              )}
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_client_id'}
                                id={'uc_ossi_client_id'}
                                name={'uc_ossi_client_id'}
                                data={formData?.uc_ossi_client_id}
                                onChange={handleInputChange}
                                label={'Client ID'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_cluster_nodes'}
                                id={'uc_ossi_cluster_nodes'}
                                name={'uc_ossi_cluster_nodes'}
                                data={formData?.uc_ossi_cluster_nodes}
                                onChange={handleInputChange}
                                label={'Cluster Nodes'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-60'>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'devcod'}
                                id={'devcod'}
                                name={'devcod'}
                                data={formData?.devcod}
                                onChange={handleInputChange}
                                label={'Devcod'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_device_grp'}
                                id={'uc_ossi_device_grp'}
                                name={'uc_ossi_device_grp'}
                                data={formData?.uc_ossi_device_grp}
                                onChange={handleInputChange}
                                label={'Device Group'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                          </div>
                          <div className='k-d-flex single-field-row single-row-80'>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_devcod_tmpl'}
                                id={'uc_ossi_devcod_tmpl'}
                                name={'uc_ossi_devcod_tmpl'}
                                data={formData?.uc_ossi_devcod_tmpl}
                                onChange={handleInputChange}
                                label={'Device Code'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_usr_id_tmpl'}
                                id={'uc_ossi_usr_id_tmpl'}
                                name={'uc_ossi_usr_id_tmpl'}
                                data={formData?.uc_ossi_usr_id_tmpl}
                                onChange={handleInputChange}
                                label={'UserDetail Id'}
                                type={'text'}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={'uc_ossi_rf_ven_nam'}
                                id={'uc_ossi_rf_ven_nam'}
                                name={'uc_ossi_rf_ven_nam'}
                                data={formData?.uc_ossi_rf_ven_nam}
                                onChange={handleInputChange}
                                label={'RF Vendor Name'}
                                type={'text'}
                                maxLength={10}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? 'ReadOnly' : ''
                                }
                                optional={false}
                                fieldWrapperStyles={{
                                  width: '100%',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              {detailsDataModified && (
                <Card className='k-mt-3'>
                  <CardHeader>
                    <div className='k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0'>
                      <>
                        <Button
                          icon={'cancel'}
                          onClick={() => onExit()}
                          type='button'
                        >
                          Cancel
                        </Button>
                        <SplitButton
                          text='save'
                          themeColor={'primary'}
                          onButtonClick={() => onSave({ isExit: false })}
                          onItemClick={handleSaveSplit}
                        >
                          <SplitButtonItem text='Save & Close' />
                        </SplitButton>
                      </>
                    </div>
                  </CardHeader>
                </Card>
              )}
            </div>
          </>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AddRemoteServer;
